document.addEventListener('keydown', (e) => {
    let kc = e.keyCode;
    for (var i = 0; i < $('.modal').length; i++) {
        if ($('.modal')[i] != null) {
            if ($('.modal')[i].id != 'category_entry_modal' && $('.modal')[i].id != 'deleteUserModal') {
                if ($('.modal')[i].style.display != "" && $('.modal')[i].style.display != null) {
                    $('.modal')[i].style.display = 'none';
                }
            }
        }
    }
});